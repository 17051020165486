
import { Component, Vue, } from 'vue-property-decorator'
import { mapGetters, } from 'vuex'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"
import CreateDialog from '@/components/CreateFolder/CreateFolder.vue'

import { BreadcrumbInterface, } from '@/store/types/Polimorfic/BreadCrumbs'
import { FoldersInterface, } from '@/store/types/Dataroom/Folder'
import { FileUploadInterface, } from '@/store/modules/dataroom/dataroomModule'
import { DataroomTemplate, } from '@/store/types/Dataroom/DataroomTemplate'

import TableEmptyState from '@/components/TableEmptyState/Index.vue'

@Component({
  components: {
    CreateDialog,
    TableEmptyState,
    OverlayBackground
  },
  computed: {
    ...mapGetters('BusinessModule', ['getSelectedProduct',]),
  },
  head: {
    script: [{ type: 'application/javascript', src: '../js/analytics.js', },],
  },
})
export default class DataRoom extends Vue {
  private Business = this.$store.state.BusinessModule.BusinessState.businessSelected
  private BreadCrumbs: BreadcrumbInterface[] = []
  private folderId: number | undefined = 0
  private files: DataroomTemplate[] = []
  private folders: FoldersInterface[] = []
  private datarooms: string[] = []
  private dataroomsName: string[] = []
  private folderSelected = {}
  private folderPermission = ''
  private dialogFolder = false
  private editFolder = false
  private permission: string | undefined = ''
  private fileToUpload: FileUploadInterface[] = []
  private searchText = ''
  private loaderUpload = false
  private loading = false
  mounted () {
    this.$store.commit('DataRoomModule/INITIAL_STATE')
    this.folderId = this.Business.folder.id
    this.BreadCrumbs.push({
      id: this.Business.folder.id,
      name: this.Business.name,
    })
    this.getFolders()
  }

  get searchT (): DataroomTemplate[] {
    let filtered: DataroomTemplate[] = this.$store.state.DataRoomModule.DataRoomState.files
    if (this.searchText) {
      filtered = this.$store.state.DataRoomModule.DataRoomState.files.filter(
        (item: DataroomTemplate) =>
          item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1
      )
      return filtered
    } else {
      return this.$store.state.DataRoomModule.DataRoomState.files
    }
  }
  get searchF (): FoldersInterface[] {
    let filtered: FoldersInterface[] = this.$store.state.DataRoomModule.DataRoomState.folders
    if (this.searchText) {
      filtered = this.$store.state.DataRoomModule.DataRoomState.folders.filter(
        (item: FoldersInterface) =>
          item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1
      )
      return filtered
    } else {
      return this.$store.state.DataRoomModule.DataRoomState.folders
    }
  }

  private createFolder () {
    this.editFolder = false
    this.dialogFolder = true
  }

  private closeDialogFolder () {
    this.dialogFolder = false
  }

  private createFolderAPI (template: string) {
    this.$store.dispatch('NotificactionsModule/OpenNotification', {
      message: 'Por favor espere, Creando carpeta',
    })
    this.$store.commit('DataRoomModule/SET_FOLDER_SELECTED', this.folderId)
    this.$store.dispatch('DataRoomModule/createFolder', template).then(() => {
      this.loaderUpload = false
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Éxito, Carpeta creada',
      })
      this.$emit('close')
      this.getFolders()
      this.$mixpanel.track('Carpeta Creada', { Nombre: template, })
    })
  }

  private previewFiles () {
    this.readbase64()
  }

  private readbase64 () {
    const refFiles: any = this.$refs.filesData
    const reader = new FileReader()
    reader.readAsDataURL(refFiles[0].files[0])
    reader.onload = () => {
      const fileAux: string = refFiles[0].files[0].name.toLowerCase()

      if (
        fileAux.split('.').pop() === 'txt' ||
        fileAux.split('.').pop() === 'pdf' ||
        fileAux.split('.').pop() === 'doc' ||
        fileAux.split('.').pop() === 'docx' ||
        fileAux.split('.').pop() === 'xls' ||
        fileAux.split('.').pop() === 'xlsx' ||
        fileAux.split('.').pop() === 'ppt' ||
        fileAux.split('.').pop() === 'pptx' ||
        fileAux.split('.').pop() === 'jpg' ||
        fileAux.split('.').pop() === 'jpeg' ||
        fileAux.split('.').pop() === 'png' ||
        fileAux.split('.').pop() === 'gif'
      ) {
        this.dataroomsName.push(fileAux)
        const readerResult: string | undefined = reader?.result?.toString()
        if (readerResult) {
          this.datarooms.push(readerResult)
        }
        this.$mixpanel.track('Archivos subidos', { 'Nombre Archivo': fileAux, })
        this.uploadFile()
      } else {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Error, Tipo de archivo no permitido',
          color_notification: 'danger',
        })
        return
      }
    }
  }

  private resetVars () {
    this.dataroomsName = []
    this.datarooms = []
    this.fileToUpload = []
  }

  private uploadFile () {
    this.loaderUpload = true
    this.files.push({ name: this.dataroomsName[0], })
    this.fileToUpload.push({
      filename: this.dataroomsName[0],
      data: this.datarooms[0],
    })
    this.$store.commit('DataRoomModule/SET_FOLDER_SELECTED', this.folderId)
    this.$store.dispatch('NotificactionsModule/OpenNotification', {
      message: 'Por favor espere, Subiendo archivo',
    })
    this.$store.dispatch('DataRoomModule/uploadFile', this.fileToUpload).then((response) => {
      this.$store.commit('DataRoomModule/SET_USER_FILES', response.data.folder.files)
      this.loaderUpload = false
      this.resetVars()
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Éxito, Archivo subido',
      })
      this.$emit('close')
    })
  }

  private deleteFolder (data: any) {
    this.$store.dispatch('NotificactionsModule/OpenNotification', {
      message: 'Por favor espere, Eliminando carpeta',
    })
    this.$store.dispatch('DataRoomModule/deleteFolder', data.id).then(() => {
      this.loaderUpload = false
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Éxito, Carpeta eliminada',
      })
      this.$mixpanel.track('Carpeta Eliminada', { Nombre: data.name, })
      this.getFolders()
      this.$emit('close')
    })
  }

  private downloadFile (data: any) {
    window.open(data.url, '_blank')
  }

  private deleteFiles (data: any) {
    this.$store.dispatch('NotificactionsModule/OpenNotification', {
      message: 'Por favor espere, Eliminando archivo',
    })
    this.$store.commit('DataRoomModule/SET_FOLDER_SELECTED_DELETE', this.folderId)
    this.$store.dispatch('DataRoomModule/deleteFile', data.id).then(() => {
      this.loaderUpload = false
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Éxito, Archivo eliminado',
      })
      this.$mixpanel.track('Archivo Eliminado', { Nombre: data.name, })
      this.getFolders()
    })
  }

  private goToFolder (tr: FoldersInterface) {
    if (tr.extension) {
      return
    }

    this.folderId = tr.id
    this.permission = tr.permission
    this.BreadCrumbs.push({
      id: tr.id,
      name: tr.name,
    })
    this.searchText = ''
    this.getFolders()
    this.$mixpanel.track(tr.name)
  }

  private goToFolderBreadcrumb (index: number) {
    if (this.BreadCrumbs.length === index + 1) {
      return
    }
    this.folderId = this.BreadCrumbs[index].id
    this.BreadCrumbs.splice(index + 1)
    this.getFolders()
  }

  private goToInitial () {
    this.goToFolderBreadcrumb(0)
  }

  private getFolders () {
    this.loading = true
    this.$store
      .dispatch('DataRoomModule/getFolders', this.folderId)
      .then((response) => {
        this.folderPermission = response.data.data.permission
        this.folders = response.data.data.folders
        this.files = response.data.data.files
        this.dialogFolder = false
        this.loading = false
      })

      .catch(() => {
        this.loading = false
      })
  }

  private editFolderData (data: FoldersInterface) {
    this.folderSelected = data
    this.editFolder = true
    this.dialogFolder = true
  }
}
